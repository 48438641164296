<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/pontos-captura" />
      </v-col>
    </v-row>

    <pontos-captura-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :ponto-captura="pontoCaptura"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import pontosCaptura from '@/api/pontos-captura'

  export default {

    components: {
      PontosCapturaCampos: () => import('./PontosCapturaCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        pontoCaptura: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await pontosCaptura.buscar(this.$route.params.id)

          this.pontoCaptura = resposta.data
          this.pontoCaptura.logradouro = resposta.data.endereco.logradouro
          this.pontoCaptura.complemento = resposta.data.endereco.complemento
          this.pontoCaptura.numero = resposta.data.endereco.numero
          this.pontoCaptura.cep = resposta.data.endereco.cep
          this.pontoCaptura.endereco_id = resposta.data.endereco.id
        } catch (e) {
          this.$router.push('/pontos-captura', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (ponto) {
        this.carregandoSalvar = true

        try {
          await pontosCaptura.atualizar(ponto.id, ponto)

          this.$router.push('/pontos-captura', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
